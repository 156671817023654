import React, { Component } from "react"
import * as styles from "./non-profits-section.module.scss"
import fetch from "node-fetch"

class NonProfitsPage extends Component {
  constructor(props) {
    console.log(props)
    super(props)

    var filterList

    try {
      filterList = props.location.state.filterList
    } catch (err) {
      filterList = false
    }

    this.state = {
      fullList: false,
      workingList: false,
      filterList: filterList,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount() {
    fetch(
      "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/gtd-non-profits.json" + cacheFix()
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        this.setState({ fullList: resultData })
        console.log("RIGHT AFTER SET STATE: ")
        console.log(this.state.fullList)
        this.setWorkingList()
      })
  }

  setWorkingList() {
    var list
    if (this.state.filterList) {
      list = this.state.fullList.filter(
        item => item.takesCalls === "Yes" || !this.state.filterList
      )
    } else {
      list = this.state.fullList
    }
    list = list.sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    this.setState({ workingList: list })
  }
  handleClick() {
    this.setState(
      state => ({ filterList: !state.filterList }),
      () => {
        this.setWorkingList()
      }
    )
  }

  render() {
    return (
  
      <div className={styles.container} id={"non-profits"}>
        <div className={styles.wrapper} >
        <div className={styles.intro}>
          <h1>Non-profits</h1>
          {!this.state.filterList && (
            <div>
              <p>These organizations benefit from your donations.</p>
              <div className={styles.highlight}>
                <p>
                  If your family is looking for assistance,{" "}
                  <span
                    onClick={this.handleClick}
                    role="button"
                    tabIndex={0}
                    onKeyDown={this.handleClick}
                  >
                    click&nbsp;here.
                  </span>
                </p>
              </div>
            </div>
          )}
          {this.state.filterList && (
            <div>
              <div className={styles.highlight}>
                <p>
                  These organizations are taking calls for families in need.
                </p>
              </div>
              <div>
                <p>
                  <span
                    onClick={this.handleClick}
                    role="button"
                    tabIndex={0}
                    onKeyDown={this.handleClick}
                  >
                    Click here for the full list of non&#8209;profits.
                  </span>
                </p>
              </div>
            </div>
            
          )}
        </div>
        
        {this.state.workingList &&
          this.state.workingList.map(org => {
            return (
              <div className={styles.organization} key={org.id}>
                <h2>{org.name}</h2>
                <div className={styles.counties}>{org.counties}</div>
                <div className={styles.phone}>
                  <a href={"tel:" + org.phone}>
                    {formatPhoneNumber(org.phone)}
                  </a>
                </div>
                <div className={styles.website}>
                  <a href={smartURL(org.url)}>{prettyURL(org.url)}</a>
                </div>
              </div>
            )
          })}
      </div>
   </div>
    )
  }
}
export default NonProfitsPage

function cacheFix() {
  return "?v=" + new Date().getTime(); 
}

function smartURL(url) {
  // console.log(url)
  var lowercase = url.toLowerCase()
  if (lowercase.substring(0, 4) === "http") {
    // console.log(">>" + url)
    return url
  } else {
    if (validateEmail(url)) {
      return "mailto:" + url
    } else {
      return "http://" + url
    }
  }
}
function prettyURL(url) {
  url = url.replace(/(^\w+:|^)\/\//, "")
  if (url.charAt(url.length - 1) === "/") {
    url = url.substring(0, url.length - 1)
  }
  if (url.substring(0, 4).toLowerCase() === "www.") {
    url = url.slice(4) //substring(4, url.length - 4)
  }
  return url
}
function validateEmail(email) {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }
  return null
}

