import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LocationListGroup from "../components/locations/location-list-group";
import PullQuote from "../components/pull-quote";
import Seo from "../components/seo";
import HowToDonateSection from "../sections/how-to-donate-section";
import NonprofitsSection from "../sections/non-profits-section";
import QuoteSection from "../sections/quote-section";
import TextSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Non-profits" mdxType="Seo" />
      <NonprofitsSection mdxType="NonprofitsSection" />
      <HowToDonateSection mdxType="HowToDonateSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      